.instructions-dashboard {
  padding: 2.25rem;

  .search {
    width: 25%;
  }

  .MuiTableContainer-root {
    // 64px = searchBar
    max-height: calc(100vh - 310px - 64px);
    overflow: auto;
    max-width: calc(100vw - 36px);
  }

  .MuiTableCell-root {
    font-family: Avenir;
    border: none;
  }

  thead .MuiTableCell-root {
    font-size: 12px;
    color: var(--color-light-grey);
    font-weight: 400;
  }

  tbody .MuiTableCell-root {
    cursor: pointer;
    font-size: 16px;

    .user-badges {
      max-width: 100%;
      flex-wrap: wrap;
    }

    .reference {
      max-width: 20vw;
    }
  }
}
