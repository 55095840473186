:root {
  // colors
  --color-carbone: #3c3c3b;
  --color-carbone-300: #797979;
  --color-carbone-500: #545454;

  --color-white: #ffffff;
  --color-near-white: #f5f6f6;
  --color-lighter-grey: #eeeeee;
  --color-light-grey: #c6c6c6;
  --color-medium-grey: #838383;
  --color-near-black: #272727;
  --color-grey-900: #3c3c3b;
  --color-grey-800: #50504f;
  --color-grey-500: #919191;
  --color-grey-300: #c8c8c8;
  --color-grey-200: #e3e3e3;

  --color-blue-500: #41a8f9;
  --color-blue-600: #1294f8;
  --color-blue-700: #0673c6;
  --color-blue-900: #022e4f;

  --color-yellow-500: #fecc00;
  --color-light-yellow: #fdf2d0;

  --color-red-400: #fd7b62;
  --color-red-500: #fc5130;

  --color-green-500: #04e762;
  --color-green-900: #09c959;

  --color-warning-800: #ff8c00;
  --color-warning-900: #ee9207;

  --color-purple-500: #832667;

  --color-transparent: #000000;

  // sizes
  --topbar-height: 72px;
  --tabsbar-height: 48px;
}
