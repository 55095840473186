.history {
  overflow: auto;
  height: 80%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem;

  .accordion {
    border: none;
    padding: 0.25rem 0;
    background-color: var(--color-near-white);
  }

  .extend {
    text-transform: none;
    color: var(--color-blue-500);
    width: 100%;
    flex: 1;
    border: 1px solid var(--color-blue-500);
  }

  .list-item-wrapper:not(:first-child) {
    display: flex;
    flex-direction: column;
    height: fit-content;

    .MuiButtonBase-root {
      width: 100%;
    }
  }
}
