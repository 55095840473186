$blue-info: #256eff;

@mixin title {
  color: #242830;
  font-family: Avenir;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
}

@mixin conflict {
  color: #242830;
  background-color: white;
  box-shadow: none;
  border: 1px solid rgba(36, 40, 48, 0.2);
  width: fit-content;
}

button.merge {
  border-radius: 23px;
  text-transform: none;
  width: 45%;
  background-color: $blue-info;
  box-shadow: none;
}

.header-buttons {
  display: flex;
  justify-content: space-between;

  .merge.conflict {
    @include conflict;
  }

  .MuiButton-endIcon svg {
    color: #f0f4ff;
  }
}

div.merge-wrapper {
  left: 32px !important;
  top: 5px !important;
  width: 20%;
  border-radius: 12px;
  background: #fff;
  z-index: 10000;

  .backdrop {
    z-index: 1000;
    color: #9aa6e4;
    background-color: #ffffffe8;
    opacity: 0.5;
  }

  .paper {
    padding: 32px;

    .title {
      @include title;
    }

    .close {
      color: #242830;
    }

    .object-search {
      margin-bottom: 20px;
    }

    .map-tooltip {
      svg {
        margin-right: 10px;
      }
      color: $blue-info;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Avenir;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%; /* 18.2px */
    }
  }
  .merge.conflict {
    @include conflict;
    width: 100%;
    margin-top: 12px;
  }
}

.merge-info {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  margin-left: 10vw;

  .paper {
    height: 40vw;
    width: 55%;
    padding: 32px;
    background-color: #f4f5f7;
    border-radius: 12px;

    .MuiChip-root {
      color: white;
      height: 20px;
      border-radius: 4px;
      font-weight: 400;
      font-size: 10px;

      &.conflict {
        background-color: var(--color-warning-900);
      }
    }

    .close {
      color: #242830;
    }

    .title {
      @include title;
    }

    .objects-wrapper {
      gap: 32px;
    }

    .info {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: calc(100% - 120px);
      justify-content: space-between;

      .container-div {
        display: flex;
        align-items: center;
        width: 45%;
        height: 100%;
        flex-direction: column;
        border-radius: 12px;
        border: 1px solid #efefef;
        box-shadow: -2px -2px 7px 0px rgba(0, 0, 0, 0.06),
          2px 2px 6px 0px rgba(0, 0, 0, 0.08);

        .main-representation {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #256eff;
          width: 100%;
          height: 42px;
          border-top-right-radius: 12px;
          border-top-left-radius: 12px;

          color: #fff;

          font-family: Avenir;
          font-size: 16px;
          font-weight: 500;
          line-height: 100%;

          &.bis {
            background-color: #f4f5f7;
            color: #242830;
          }
        }

        .history {
          width: 100%;
          height: 100%;
          background-color: white;
          display: flex;
          flex-direction: column;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
}
