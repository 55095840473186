#details-panel-content {
  height: 100%;

  .tab-panel {
    flex: 1;
    height: 100%;
    padding: 0;
  }

  &.blur {
    opacity: 0.3;
  }
}
