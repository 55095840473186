#general-info {
  overflow: auto;
  height: 44vh;

  .region-dropdown {
    width: 100%;
    background-color: var(--color-near-white);

    fieldset {
      border: none;
    }
  }
}
