.projections-dashboard {
  padding: 2.25rem;

  .MuiTableContainer-root {
    max-height: calc(100vh - 310px);
    overflow: auto;
  }

  .MuiTableCell-root {
    font-family: Avenir;
    border: none;
  }

  thead .MuiTableCell-root {
    font-size: 12px;
    color: var(--color-light-grey);
    font-weight: 400;
  }

  tbody .MuiTableCell-root {
    font-size: 16px;
  }
}