.history-modal {
  padding: 0 1.5rem;
  position: relative;

  .backdrop {
    z-index: 100;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
  }

  .MuiDialog-container {
    .MuiPaper-root {
      min-width: 25%;
    }
  }
  .attributes-container {
    padding: 0;
  }

  #linked-objects {
    padding: 0;
  }
}
