#area-modal {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .MuiPaper-root {
    height: 630px;
    width: 450px;
    padding: 1.5rem;
  }

  h1 {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 500;
  }

  .wrapper {
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    .region-dropdown {
      width: 100%;
    }

    .select-label {
      margin-bottom: 6px;
      padding: 0px;
      color: rgba(0, 0, 0, 0.6);
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
    }
  }
}
