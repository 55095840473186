.list-item-wrapper {
  background-color: var(--color-near-white) !important;
  height: 82px;
  padding-left: 1.5rem;
  border-radius: 4px;

  .list-text {
    font-family: Avenir;
    font-weight: 400;
    font-size: 12px;
    color: var(--color-grey-900);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .list-subtitle {
    font-family: Avenir;
    font-size: 12px;
    color: var(--color-blue-500);
  }

  .icon {
    width: 9px;
  }

  .MuiListItemButton-root {
    height: 82px;
  }

  &.multiple {
    .MuiListItemButton-root {
      padding-right: 88px;
    }
  }

  .conflict {
    color: #ee9207;
  }
}
