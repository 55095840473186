.details-title {
  .title {
    width: 100%;
    margin-right: 10px;
    padding-top: 3px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .subtitle {
    font-family: Avenir;
    font-size: 12px;
    color: var(--color-blue-500);
    width: 100%;
  }

  .object-infos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;

    .MuiChip-root {
      color: white;
      height: 20px;
      border-radius: 4px;
      font-weight: 400;
      font-size: 10px;

      &.deleted {
        background-color: var(--color-red-500);
      }

      &.conflict {
        background-color: var(--color-warning-900);
      }

      &.no-instruction {
        background-color: var(--color-warning-800);
      }

      &.validated {
        background-color: var(--color-light-yellow);
        color: #242830;
      }

      &.verified {
        background-color: var(--color-blue-500);
      }

      &.approved {
        background-color: var(--color-green-900);
      }

      &.todo {
        background-color: #f0f0f0;
        color: #242830;
      }
    }
  }
}
