.region-select {
  div.MuiSelect-select {
    font-weight: 500;
    font-family: Avenir;
    color: var(--color-white);
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: inherit;
  }
  svg {
    color: var(--color-white);
  }
}
