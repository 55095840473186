.new-perimeter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  .colored-title {
    min-height: 120px;
    background-color: var(--color-blue-900);
  }

  .return-wrapper {
    position: absolute;
    width: 250px;
    right: 20px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiButton-root {
      border: none;
      text-transform: none;
      color: white;
    }
  }

  h1 {
    color: white;
    font-size: 36px;
    font-weight: 900;
  }

  .update-banner {
    margin-left: 4rem;
    width: 100%;

    h1 {
      font-size: 24px;
      color: white;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  h3 {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-light-grey);
    text-transform: none;
  }

  .button-wrapper {
    height: 70px;
    position: fixed;
    bottom: 15px;
  }

  .reset-polygon-button {
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 2000;
    border-radius: 20px;
    background-color: var(--color-carbone);
    svg {
      color: white;
    }
  }
}
