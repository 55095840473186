.user-multi-select {
  .MuiInput-root {
    margin-top: 1.5rem;
    padding: 15px !important;
    height: unset;
    min-height: 56px;
    border: solid 1px var(--color-medium-grey);
    border-radius: 4px;
    height: unset !important;
  }

  .MuiAutocomplete-endAdornment {
    right: 20px;
  }
}

.MuiAutocomplete-popper {
  max-height: 50px;

  .MuiAutocomplete-noOptions,
  .MuiAutocomplete-option,
  .MuiAutocomplete-loading {
    font-family: Avenir;
    font-weight: 400;
    font-size: 12px;
    color: black;
  }

  .MuiAutocomplete-listbox {
    max-height: 200px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
