.subareas {
  .title {
    font-size: 12px;
  }

  .subareas-list {
    overflow: auto;
    height: 160px;
  }

  .subarea-wrapper {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiFormControl-root {
      padding-right: 1rem;
    }

    .MuiInputBase-root {
      border-radius: 4px;
    }

    .MuiInputBase-input {
      padding: 15px;
    }
  }
}
