.legend {
  position: absolute;
  align-items: center;
  bottom: 25px;
  right: 10px;
  display: flex;
  flex-direction: row;
  z-index: 1;
  padding: 8px;
  height: auto;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 12px;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: white;
  column-gap: 10px;

  .wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 4px;

    .color {
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }
  }
}
