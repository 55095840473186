.region-dropdown {
  padding: 0;
  div.MuiSelect-select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-left: 16px;
  }
}
