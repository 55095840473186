.map-wrapper {
  flex: 1;
  background-color: var(--color-near-white) !important;

  .mapboxgl-ctrl-bottom-right:has(.mapboxgl-ctrl-attrib) {
    left: 0;
    right: auto;
  }

  &.blur {
    opacity: 0.3;
  }

  .object-search {
    top: 20px;
    position: absolute;
    .autocomplete-search {
      width: 250px;
      background-color: var(--color-white);

      .input {
        border: 0;
        border-radius: 4px;
        height: 56px;
      }
    }
  }
}
