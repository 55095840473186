div.search-wrapper {
  left: 32px;
  width: 20%;
  border-radius: 12px;
  background: #fff;
  z-index: 10000;

  #backdrop {
    z-index: 1000;
    color: #9aa6e4;
    background-color: #ffffffe8;
    opacity: 0.5;
  }

  .paper {
    padding: 32px;

    .title {
      color: #242830;
      font-family: Avenir;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
    }

    .close {
      color: #242830;
    }

    .object-search {
      margin-bottom: 20px;
    }

    .map-tooltip {
      svg {
        margin-right: 10px;
      }
      color: #256eff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Avenir;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 130%; /* 18.2px */
    }
  }
}
