.titles-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .label {
    margin-right: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #242830;
    font-family: Avenir;
    font-size: 24px;
    flex: 1;
  }
  .subtitle {
    display: flex;
    flex-basis: 100%;
    font-family: Avenir;
    font-size: 12px;
    color: var(--color-blue-500);
  }
}
