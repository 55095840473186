#details-panel {
  .details-title {
    font-size: 20px;
    font-weight: 400;
    font-family: Avenir;
    color: var(--color-carbone);
  }

  .divider {
    border-width: 0.5px;
    border-color: #c4c4c4;
    height: 50%;
  }

  .deletion {
    &.Mui-disabled {
      opacity: 0.5;
    }
  }

  .tab-panel {
    display: flex;
  }

  .padded {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }

  &.reduced {
    .object-attributes {
      height: calc(100% - 170px);
    }
  }

  .object-attributes {
    height: calc(100% - 110px);
    padding-top: 1rem;
    overflow: auto;
    padding-bottom: 160px;
  }

  .protection-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

#linked-objects {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .MuiAccordion-root {
    box-shadow: none;

    &::before {
      content: none;
    }
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionSummary-content {
    font-family: Avenir;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-grey-900);
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
}
